/**
 * /* eslint-disable no-negated-condition
 *
 * @format
 */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
/**
 * /* eslint react/prop-types: 1, @typescript-eslint/no-unsafe-assignment: 1, @typescript-eslint/no-unsafe-call: 1, @typescript-eslint/restrict-template-expressions: 1, no-negated-condition: 1
 *
 * @format
 */

import React from 'react';
import {Box, Container, Heading, Text} from '@chakra-ui/layout';
import TitleOverline from '../components/TitleOverline';
import Patterns from '../patterns';
import Layout from '../components/Layout';
import {getContentNode} from '../lib/api/contentNodes';
import {getSeoFields} from '../lib/getSeoFields';
import {NextSeo} from 'next-seo';
import getMainMenu from '../lib/api/acf/mainMenu';
import mappingMainMenu from '../patterns/MainMenu/mappingMainMenu';
import getFooter from '../lib/api/acf/footer';
import mappingFooter from '../patterns/Footer/mappingFooter';

const MaintenanceMsg = () => (
	<Container py={{base: '16', md: '24'}} maxW='container.xl' mx='auto'>
		<Heading as='h1' fontSize={'2.5rem'}>
			The site is currently under routine maintenance.
		</Heading>
		<Box h={'3rem'} />
		<Text fontSize={'1.20rem'}>
			We apologise for any inconvenience that this has caused. Please check
			again later.
		</Text>
	</Container>
);

// eslint-disable-next-line react/prop-types
function HomePage({post, seo, mainMenu, footer}) {
	if (!post) return null;

	if (process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true')
		return <MaintenanceMsg />;

	return (
		/* eslint-disable-next-line react/prop-types, @typescript-eslint/no-unsafe-assignment */
		<Layout seo={post.seo} mainMenu={mainMenu} footer={footer}>
			<NextSeo {...seo} />

			<Box>
				{/* eslint-disable-next-line react/prop-types, @typescript-eslint/no-unsafe-call */}
				{post.pageLayout.flexibleContent.map((post: any, index: number) => (
					<Patterns
						/* eslint-disable-next-line react/prop-types, @typescript-eslint/no-unsafe-assignment */
						fieldGroupName={post.__typename}
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						post={post}
						// eslint-disable-next-line react/prop-types
						key={`${post.fieldGroupName}_${index}`}
					/>
				))}
			</Box>
		</Layout>
	);
}

export async function getStaticProps({params, preview, previewData}) {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const id = preview ? previewData.id : '/';
	const idType = preview ? 'ID' : 'URI';
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const post = await getContentNode(id, idType, preview);
	// eslint-disable-next-line no-negated-condition
	const seo = !preview ? getSeoFields(post) : null;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	let mainMenu = await getMainMenu();
	mainMenu = mappingMainMenu(mainMenu);
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	let footer = await getFooter();
	footer = mappingFooter(footer);

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	return {props: {post, seo, mainMenu, footer}};
}

export default HomePage;
